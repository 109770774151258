@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

html {
  /* apply a natural box layout model to all elements */
  box-sizing: border-box;
  background-color: $background-color;
  font-size: 16px;

  @include breakpoint($medium) {
    font-size: 18px;
  }

  @include breakpoint($large) {
    font-size: 20px;
  }

  @include breakpoint($x-large) {
    font-size: 22px;
  }

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

/*
   Breakpoints
   ========================================================================== */

$small: 600px !default;
$medium: 768px !default;
$medium-wide: 900px !default;
$large: 1024px !default;
$x-large: 1280px !default;
$max-width: $x-large !default;

/*
   Grid
   ========================================================================== */

// $right-sidebar-width-narrow: 200px !default;
// $right-sidebar-width: 300px !default;
// $right-sidebar-width-wide: 400px !default;
// kgd
$right-sidebar-width-narrow: 200px !default;
$right-sidebar-width: 200px !default;
$right-sidebar-width-wide: 200px !default;
