// Adds support for a video holder
// as per http://webdesignerwall.com/tutorials/css-elastic-videos

.myvideo {
position : relative;
display : block;
width : 100%;
min-width : 200px;
margin : 0 auto 1.3em auto;
height : auto;
}
.flex-video {
position : relative;
padding-bottom : 67.5%;
height : 0;
overflow : hidden;
}
.flex-video iframe, .flex-video object, .flex-video embed {
position : absolute;
top : 0;
left : 0;
width : 100%;
height : 100%;
}

.myvideo video {
    margin: auto;
    max-width: 800px;
}
